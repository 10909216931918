import sliderActions from "../../Constant/actionConstants";

const initState = {
  sliders: [],
};

const sliderReducer = (state = initState, action) => {
  if (action.type === sliderActions.FETCH_SLIDER_SUCCESS) {
    return {
      ...state,
      sliders: action.payload?.data,
    };
  }

  return state;
};

export default sliderReducer;
