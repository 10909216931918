import axios from "axios";
import { authClient } from "./clients/authClient";
const baseUrl = process.env.REACT_APP_API_URL;

export function getAllCategory(payload) {
  return new Promise((resolve, reject) =>
    authClient
      .get("/category")
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      })
  );
}
