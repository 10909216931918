import { getAllCategory } from "../../services/CategoryServices";
import Actions from "../../Constant/actionConstants";

const fetchCategorySuccess = (cat) => ({
  type: Actions.FETCH_CATEGORY_SUCCESS,
  payload: cat.data,
});

// fetch products
export const fetchCategory = () => {

  return async (dispatch) => {
    let { data } = await getAllCategory();
    console.log(data)
    dispatch(fetchCategorySuccess(data));
  };
};

