import Actions from "../../Constant/actionConstants";

const initState = {
  brands: [],
  allBrands: [],
};

const brandReducer = (state = initState, action) => {
  switch (action.type) {
    case Actions.FETCH_BRAND_SUCCESS:
      return {
        ...state,
        brands: action.payload,
      };

    default:
      return state;
  }
};

export default brandReducer;
