import {
  addProduct,
  getAllProduct,
  getProductDetail,
} from "../../services/ProductServices";
import productAction from "../../Constant/actionConstants";

const fetchProductsSuccess = (products) => ({
  type: productAction.FETCH_PRODUCTS_SUCCESS,
  payload: products,
});

// fetch products
export const fetchProducts = (params) => {
  return async (dispatch) => {
    const { data } = await getAllProduct(params);
    dispatch(fetchProductsSuccess(data));
  };
};

export const ProductDetail = (payload) => {
  const product = getProductDetail(payload);
  return (dispatch) => {
    dispatch({
      type: productAction.FETCH_PRODUCT_DETAILS,
      payload: product.sata.data,
    });
  };
};

export const add = (payload) => {
  return async (dispatch) => {
    const product = await addProduct(payload);
    console.log({ product });
    dispatch({ type: productAction.ADD_PRODUCT, payload: product });
    return product.data.data;
  };
};
