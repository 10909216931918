export default {
  FETCH_PRODUCTS_SUCCESS: "FETCH_PRODUCTS_SUCCESS",
  FETCH_PRODUCT_DETAILS: "FETCH_PRODUCT_DETAILS",
  UPDATE_PRODUCT_DETAILS: "UPDATE_PRODUCT_DETAILS",
  GET_SEARCH_PRODUCT: "GET_SEARCH_PRODUCT",
  GET_FILTER_PRODUCT: "GET_FILTER_PRODUCT",
  ADD_PRODUCT: "ADD_PRODUCT",
  //login Accounts//
  USER_LOGIN: "USER_LOGIN",
  USER_REGISTER: "USER_REGISTER",
  USER_LOGOUT: "USER_LOGOUT",

  // Category Actions //
  FETCH_CATEGORY_SUCCESS: "FETCH_CATEGORY_SUCCESS",

  //Brands Actions //
  FETCH_BRAND_SUCCESS: "FETCH_BRAND_SUCCESS",

  // Fetch slider//
  FETCH_SLIDER_SUCCESS: "FETCH_SLIDER_SUCCESS",
};
