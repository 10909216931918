import actionConstants from "../../Constant/actionConstants";

const initState = {
  user: localStorage.getItem("user") ? localStorage.getItem("user") : {},
};

const userReducer = (state = initState, action) => {
  if (action.type === actionConstants.USER_LOGIN) {
    return {
      ...state,
      user: action.user,
    };
  }
  if (action.type === actionConstants.USER_LOGOUT) {
    return {
      ...state,
      user: null,
    };
  }

  return state;
};

export default userReducer;
