import Axios from "axios";
import { getToken } from "../../utils/authentication";

const token = getToken();
const baseUrl = process.env.REACT_APP_API_URL;

export const authClient = Axios.create({
  baseURL: baseUrl,
  headers: { authorization: `Bearer ${token}` },
});
