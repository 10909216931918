import productAction from "../../Constant/actionConstants";

const initState = {
  products: [],
  pagination: { totalPages: 1, totalProduct: 0, currentPage: 1 },
};

const productReducer = (state = initState, action) => {
  if (action.type === productAction.FETCH_PRODUCTS_SUCCESS) {
    return {
      ...state,
      products: action.payload?.data,
      pagination: {
        totalPages: action.payload?.totalPages,
        totalProducts: action.payload?.totalItems,
        currentPage: action.payload?.currentPage,
      },
    };
  }

  return state;
};

export default productReducer;
