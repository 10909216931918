import currencyReducer from "./currencyReducer";
import productReducer from "./productReducer";
import cartReducer from "./cartReducer";
import wishlistReducer from "./wishlistReducer";
import compareReducer from "./compareReducer";
import categoryReducer from "./categoryReducers";

import { combineReducers } from "redux";
import { createMultilanguageReducer } from "redux-multilanguage";
import userReducer from "./userReducer";
import brandReducer from "./brandReducer";
import sliderReducer from "./sliderReducer";

const rootReducer = combineReducers({
  multilanguage: createMultilanguageReducer({ currentLanguageCode: "en" }),
  currencyData: currencyReducer,
  productData: productReducer,
  cartData: cartReducer,
  wishlistData: wishlistReducer,
  compareData: compareReducer,
  user: userReducer,
  category: categoryReducer,
  brandData: brandReducer,
  slider: sliderReducer,
});

export default rootReducer;
