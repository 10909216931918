import axios from "axios";
import { authClient } from "./clients/authClient";
const baseUrl = process.env.REACT_APP_API_URL;
const url = process.env.REACT_APP_API_URL + "/product/";

export async function getAllProduct(query) {
  let product = await new Promise((resolve, reject) =>
    axios
      .get(url, { params: query })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      })
  );
  return product;
}

export function getProductDetail(payload) {
  let uri = url + `details/${payload.id}`;
  return new Promise((resolve, reject) => {
    axios
      .get(uri, payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function addProduct(payload) {
  return new Promise((resolve, reject) => {
    authClient
      .post(url, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "*/*",
        },
      })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function searchProduct(query) {
  const uri = baseUrl + "search";
  return new Promise((resolve, reject) => {
    axios
      .get(uri, { params: query })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function bid(id, data) {
  const uri = url + `bid/${id}`;

  return new Promise((resolve, reject) => {
    axios
      .post(uri, data)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getProductByUserId(id, query) {
  const uri = url + `user/${id}`;
  return new Promise((resolve, reject) => {
    axios
      .get(uri, {
        params: query,
      })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
